<script setup>
import { ref, onMounted, computed } from "vue";

import personIcon from "@/assets/img/icons/person.svg";
import peopleIcon from "@/assets/img/icons/people.svg";
import linkIcon from "@/assets/img/icons/link.svg";
import paperPlaneIcon from "@/assets/img/icons/paper_plane.svg";
import manyPeopleIcon from "@/assets/img/icons/many_people.svg";
import personAddIcon from "@/assets/img/icons/person_add.svg";

import { API } from "@/services/api";

import CircleLoader from "@/components/CircleLoader.vue";
import Popup from "@/components/common/Popup.vue";
import Button from "@/components/common/Button.vue";
import Input from "@/components/common/Input.vue";

const rewardsData = ref();
const loading = ref(true);

const rewardsList = {
    "20_queries": "20 queries",
    "7d_trial": "7 day free trial",
    promo_code: "Discount code",
    none: "N/A"
};

const showInvitePopup = ref(false);
const inviteText = ref("Try Wiseone, the AI assistant to speed up and densify your search experience. Analyse web pages for instant insights, Summarize online articles and papers, or Ask complex questions for deep and sourced answers—all within your browser.\nBy using my invite link, you'll get an extra 7 days for your Wiseone Pro free trial.");
const inviteTextTwitter = ref("Try Wiseone, the AI assistant to speed up your search experience. Analyse web pages, Summarize online articles or Ask complex questions for sourced answers—all within your browser.\nUse my link to get an extra 7 days for your Wiseone Pro free trial.");

const inviteLink = computed(() => `https://app.wiseone.io/invite?invite_code=${rewardsData.value.code}`);

function copyInviteLink() {
    navigator.clipboard.writeText(inviteLink.value);
}

function copyInviteText() {
    navigator.clipboard.writeText(inviteText.value);
}

onMounted(async () => {
    loading.value = true;

    const res = await API.rewards.getRewards();
    rewardsData.value = res;
    inviteText.value = `${inviteText.value}\n${inviteLink.value}`;

    loading.value = false;
});
</script>

<template>
    <div>
        <div class="header">
            <div class="header-titles">
                <h1 class="header-title">Rewards Program</h1>
                <h3 class="header-subtitle">Rewards</h3>
                <span class="header-description">Invite friends and colleagues to try out Wiseone and get rewards</span>
            </div>

            <button class="header-action share" @click="showInvitePopup = true">
                <img :src="linkIcon">
                Invite a friend
            </button>
            <Popup v-model:show="showInvitePopup">
                <template #header>
                    <img :src="paperPlaneIcon" class="invite-icon">
                    <h1>Invite your friends</h1>
                </template>
                <div class="popup-content">
                    <div class="invite">
                        <span class="invite-intro">
                            Invite your friends to try Wiseone and get rewards when they create their account!
                        </span>
                        <div class="invite-code-copy">
                            <Input
                                disabled
                                v-model="inviteLink"
                                class="copy-code-input"
                            />
                            <Button
                                @click="copyInviteLink"
                                class="copy-code-button"
                            >
                                Copy
                            </Button>
                        </div>
                        <div>
                            <div class="sample-text-container">
                                <span>
                                    Share it anywhere
                                </span>
                                <button
                                    @click="copyInviteText"
                                    class="copy-button"
                                >
                                    <img src="@/assets/img/icons/copy.svg">
                                    Copy
                                </button>
                            </div>
                            <Input
                                v-model="inviteText"
                                multiline
                                class="invite-text"
                            />
                        </div>
                        <div class="social-share-buttons">
                            <a class="social-share-button" target="_blank" :href="`https://wa.me/?text=${encodeURIComponent(inviteLink)}`">
                                <img src="@/assets/img/logo/whatsapp_color.svg">
                            </a>
                            <a class="social-share-button" target="_blank" :href="`https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(inviteText)}`">
                                <img src="@/assets/img/logo/telegram_color.svg">
                            </a>
                            <a class="social-share-button" target="_blank" :href="`https://x.com/compose/post?text=${encodeURIComponent(`${inviteTextTwitter}\n${inviteLink}`)}`">
                                <img src="@/assets/img/logo/x_color.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
        <div class="card progress">
            <div class="steps">
                <div class="progress-step">
                    <h4>
                        <img class="step-icon" :src="personIcon">
                        1 friend
                    </h4>
                    <span>
                        20 queries
                    </span>
                </div>
                <div class="progress-step">
                    <h4>
                        <img class="step-icon" :src="peopleIcon">
                        2 friends
                    </h4>
                    <span>
                        1 week Free Trial
                    </span>
                </div>
                <div class="progress-step">
                    <h4>
                        <img class="step-icon" :src="manyPeopleIcon">
                        3 friends
                    </h4>
                    <span>
                        Up to 50% off Wiseone Pro
                    </span>
                </div>
                <div class="progress-step">
                    <h4>
                        <img class="step-icon" :src="personAddIcon">
                        More friends
                    </h4>
                    <span>
                        20 queries each time
                    </span>
                </div>
            </div>
        </div>

        <div class="header">
            <h3 class="header-subtitle">Your friends</h3>
            <span class="header-description">Rewards you have already acquired</span>
        </div>

        <div class="card friends">
            <CircleLoader v-if="loading" class="loader" />
            <template v-else-if="!rewardsData.redeems.length">
                <div class="no-results">
                    You haven't acquired any rewards yet, invite friends now!
                </div>
            </template>
            <template v-else>
                <div class="line titles">
                    <div class="line-cell email">
                        Email
                    </div>
                    <div class="line-cell rewards">
                        Reward
                    </div>
                    <div class="line-cell date">
                        Date
                    </div>
                </div>
                <div v-for="inviteeInfo in rewardsData.redeems" :key="inviteeInfo.invitee" class="line">
                    <div class="line-cell email">
                        {{ inviteeInfo.invitee }}
                    </div>
                    <div class="line-cell rewards">
                        {{ rewardsList[inviteeInfo.reward] }}
                    </div>
                    <div class="line-cell date">
                        {{ (new Date(inviteeInfo.date)).toLocaleDateString() }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
.card {
    font-family: Quicksand;
    font-size: 15px;
    padding: 40px 32px;
    background-color: white;
    width: 100%;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.card:not(:last-child) {
    margin-bottom: 32px;
}

.card .header-title {
    color: #3c3c3c;
    font-family: "Quicksand";
    font-size: 24px;
    font-weight: 600;
}

.card .loader {
    margin: 32px 0;
    width: 100%;
}

.progress {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Progress */

.steps {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.progress-step {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 12px 8px;
}

.progress-step h4 {
    padding: 12px 0;
    border-bottom: 1px solid #F2F5F5;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
}

.progress-step h4 img {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

.progress-step span {
    line-height: 24px;
}

/* Share */

.share {
    color: white !important;
    background-color: #15AED3;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0px 4px 20px rgba(47, 34, 96, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    transition: .1s linear;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
}

.share:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

.share img {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(206deg) brightness(103%) contrast(103%);
}

/* Friends */

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 8px;
}

.line.titles {
    border-bottom: 1px solid #F2F5F5;
    font-weight: 700;
    margin-bottom: 4px;
}

.line-cell {
    display: flex;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.line-cell.rewards, .line-cell.date {
    max-width: 160px;
    margin-left: 5px;
}

/* No Results */

.no-results {
    display: flex;
    width: 100%;
    padding: 32px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

/* Popup */

.popup-content {
    width: 500px;
}

.invite {
    display: flex;
    flex-direction: column;
}

.invite-intro {
    line-height: 22px;
    font-weight: 500;
}

.invite-code-copy {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 24px 0;
}

.copy-code-input {
    width: 100%;
}

.copy-code-button {
    margin: 0;
    width: 80px;
    font-family: Quicksand;
    font-weight: 600;
    color: white;
    background-color: #2d00d4;
}

.copy-code-button:hover {
    box-shadow: 0px 5px 10px 0px #11094e46;
}

:deep(.invite-text textarea) {
    height: 130px !important;
}

.invite-icon {
    height: 18px !important;
}

.sample-text-container {
    display: flex;
    flex-direction: row;
}

.sample-text-container span {
    color: #2d00d4;
    font-weight: 600;
}

.copy-button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 8px;
    background: none;
    border: none;
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 600;
    gap: 4px;
    margin-left: auto;
    transition: all .1s linear;
    color: #BAB3D3;
}

.copy-button img {
    height: 18px;
    transition: all .1s linear;
    filter: invert(75%) sepia(4%) saturate(1410%) hue-rotate(212deg) brightness(100%) contrast(85%);
}

.copy-button:hover {
    color: #2d00d4;
}

.copy-button:hover img {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

textarea {
    resize: none;
    height: 100px;
    overflow-y: hidden;
    font-size: 15px;
    padding: 12px;
}

.social-share-buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: end;
    margin-top: 12px;
}

.social-share-button {
    display: flex;
    height: 35px;
    opacity: 50%;
    transition: all .1s linear;
}

.social-share-button:hover {
    opacity: 100%;
}

</style>
