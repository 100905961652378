<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import LoginCard from "./LoginCard.vue";
import CircleLoader from "@/components/CircleLoader.vue";

const route = useRoute();

const errorText = ref("");

onMounted(() => {
    if (!route.query.invite_code) {
        errorText.value = "No invite code found, please check your link is correct";
        return;
    }

    localStorage.setItem("wiseoneInviteCode", route.query.invite_code);
    let redirectUrl = "https://chromewebstore.google.com/detail/wiseone-your-ai-powered-r/paodpkkacimmkacaecjmhdncjgjepcai";
    if (window.navigator.userAgentData?.brands?.find((e) => e.brand === "Microsoft Edge")) {
        redirectUrl = "https://microsoftedge.microsoft.com/addons/detail/wiseone-your-aipowered/pcgccjfobmmgjkkpcabmiiefmnjegpfg";
    }
    location.href = redirectUrl;
});
</script>

<template>
    <div>
        <LoginCard title="Please wait while you are being redirected">
            <template #content>
                <CircleLoader v-if="!errorText"></CircleLoader>
                <div class="error" v-else>
                    <span class="material-icons">error</span>
                    <span>{{ errorText }}</span>
                </div>
            </template>
        </LoginCard>
    </div>
</template>

<style scoped>
</style>
