<script setup>
const show = defineModel("show");
const emit = defineEmits([ "closed" ]);
</script>

<template>
    <Teleport to="body">
        <div v-if="show" class="popup-container" @mousedown.stop="show = false; emit('closed')">
            <div class="popup" @mousedown.stop>
                <div class="popup-header">
                    <slot name="header"></slot>
                </div>
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<style scoped>
.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    padding: 32px;
    background-color: white;
    border-radius: 24px;
    color: #242424;
    font-family: Quicksand;
    font-size: 16px;
}

.popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    border-bottom: 1px solid #f2f5f5;
    padding-bottom: 20px;
}

:deep(.popup-header img) {
    height: 14px;
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
    cursor: pointer;
}

:deep(.popup-header h1) {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    cursor: default;
}

.content {
    padding-top: 20px;
}
</style>
