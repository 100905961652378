<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { API } from "@/services/api";
import Accordion from "@/components/common/Accordion.vue";
import CircleLoader from "@/components/CircleLoader.vue";

import useClientConfig from "@/stores/client-config";

import AvailablePlans from "./AvailablePlans.vue";
import Community from "./Community.vue";
import CurrentPlan from "./CurrentPlan.vue";

const route = useRoute();
const router = useRouter();
const clientConfigStore = useClientConfig();

const faQuestions = [
    {
        question: "Where can I find my invoice?",
        // eslint-disable-next-line max-len
        answer: "If you are on the Pro plan, you can find your invoice in manage subscriptions. If you downgraded from Wiseone Pro and are now on the free plan, you can find your invoice by clicking \"billing history\".",
    },
    {
        question: "What is Wiseone Pro?",
        // eslint-disable-next-line max-len
        answer: "Wiseone Pro is the premium version of our browser extension. While Wiseone offers a range of valuable features for enhancing your web search and online reading experience, Wiseone Pro takes it a step further by providing advanced features and unlimited usage.",
    },
    {
        question: "Is Wiseone a productivity tool?",
        // eslint-disable-next-line max-len
        answer: "With features designed to enhance productivity and efficiency, Wiseone minimizes the time spent reading and searching for information. By combining time-saving capabilities with a user-friendly interface, Wiseone becomes a valuable tool for maximizing productivity for web searches and online reading.",
    },
    {
        question: "How do I cancel Wiseone Pro?",
        // eslint-disable-next-line max-len
        answer: "To cancel your Wiseone Pro subscription, click Manage Subscriptions, then cancel it from the Stripe portal.",
    },
    {
        question: "Help with login and account settings",
        // eslint-disable-next-line max-len
        answer: "If you're experiencing difficulties with logging in or managing your account settings, we recommend first resetting your password. If the issue persists or you have any other concerns regarding your account, please contact us at support@wiseone.io. Our dedicated support team will be more than happy to assist you further.",
    },
    {
        question: "What are the payment options?",
        // eslint-disable-next-line max-len
        answer: "Currently, we accept credit cards, PayPal and Stripe Link. This flexibility allows you to choose the payment method that suits your preference and convenience. We will add more payment method in the futur.",
    },
    {
        question: "What's the difference between Ask Anything and Deep Ask?",
        // eslint-disable-next-line max-len
        answer: "The key distinction between Ask Anything and Deep Ask lies in the level of detail of information provided.  While Ask Anything offers a comprehensive response to your questions, Deep Ask takes it a step further by providing more in-depth information from a diverse range of sources. Because the answer is more developed, we structure the data differently to maximize a quick understanding.",
    },
    {
        question: "What's the difference between Summarize and Deep Summarize?",
        // eslint-disable-next-line max-len
        answer: "The distinction lies in the depth of the summaries. While Summarize provides a concise overview of the content, Deep Summarize takes it a step further by offering a more detailed summary enriched with extended context.",
    },
    {
        question: "How does Wiseone enhance web search?",
        // eslint-disable-next-line max-len
        answer: "Wiseone enhances your web search by expanding your knowledge, offering reliable information as you browse, and providing well-sourced answers to complex questions. Whether you're looking to be more productive, learn more, or even verify facts while browsing. Wiseone is designed to be a valuable companion, enhancing the efficiency of your web search.",
    },
    {
        question: "Does Wiseone work on all websites?",
        // eslint-disable-next-line max-len
        answer: "Yes, Wiseone works everywhere online. Simply click on the \"Enable Wiseone on\" button in the extension's menu.",
    },
    {
        question: "Will Wiseone slow down my browsing?",
        // eslint-disable-next-line max-len
        answer: "Wiseone only uses a small amount of your computer's resources when it displays its results on web pages and therefore does not affect your browsing experience noticeably. Every analysis is performed on our servers, and the extension's job is simply to give the results back to you by inserting them directly in the article you're reading. You will only need a stable internet connexion.",
    },
];
const loadingPlan = ref(false);

// Billing

const billingInfo = ref({});
const redirectLoading = ref(false);
const hasSummerSaleOffer = computed(() => {
    const { clientConfig } = clientConfigStore;
    return clientConfig.offers && clientConfig.offers.includes("summer_sale");
});

async function upgradePlan(selectedPaymentSchedule) {
    redirectLoading.value = true;

    const checkoutSession = await API.billing.createCheckoutSession({
        plan: "pro",
        schedule: selectedPaymentSchedule,
    });

    window.gtag("event", "begin_checkout");
    window.location.href = checkoutSession.checkout_url;
}

async function goToPortal() {
    redirectLoading.value = true;

    const portalSession = await API.billing.createBillingPortalSession();

    window.location.href = portalSession.portal_url;
}

onMounted(async () => {
    const { result, ...query } = route.query;
    if (result === "success") window.gtag("event", "purchase");
    if (result) {
        window.gtag("event", `checkout_${result}`);
        await router.replace({ query });
    }

    loadingPlan.value = true;

    const billingInfoRes = await API.billing.getBillingInfo();
    Object.assign(billingInfo.value, billingInfoRes);

    loadingPlan.value = false;
});

function copyToClipboard(toCopy) {
    navigator.clipboard.writeText(toCopy);
}
</script>

<template>
    <div>
        <div class="header">
            <div class="header-titles">
                <h1 class="header-title">Subscription</h1>
                <h3 class="header-subtitle">Enhance web search. Boost reading productivity with AI</h3>
                <span class="header-description">Get Wiseone Pro and enjoy unlimited access to our features</span>
            </div>
            <button
                v-if="billingInfo.portal_available && !billingInfo.subscription"
                @click="goToPortal"
                class="header-action billing-history"
            >
                <img src="@/assets/img/icons/file.svg">
                Billing history
            </button>
        </div>

        <div v-if="redirectLoading" class="loading">
            <CircleLoader></CircleLoader>
            <div class="loading-message">
                Redirecting to Stripe<br/>
                <span class="do-not-close">Please do not close this tab</span>
            </div>
        </div>

        <div v-else-if="loadingPlan" class="loading">
            <CircleLoader></CircleLoader>
            <div class="loading-message">
                Loading your current plan<br/>
            </div>
        </div>

        <template v-else-if="!loadingPlan">
            <div class="banner-sale" v-if="!hasSummerSaleOffer">
                <h3>Back to Business sale</h3>
                <div>
                    <div class="main-text">Enjoy Wiseone Pro and all its features for a year at reduced prices!</div>
                    <ul>
                        <li>
                            <b>-40%</b> for a full year of Wiseone Pro at $59.4 with code
                            <button
                                @click="copyToClipboard('WPRO40')"
                                class="copy"
                            >
                                WPRO40
                                <img src="@/assets/img/icons/copy.svg">
                            </button>
                        </li>
                        <li>
                            <b>-30%</b> each month for the first year at $6.93/m with code
                            <button
                                @click="copyToClipboard('WPRO30')"
                                class="copy"
                            >
                                WPRO30
                                <img src="@/assets/img/icons/copy.svg">
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <CurrentPlan
                v-if="billingInfo.subscription && billingInfo.subscription.plan !== 'trial'"
                @go-to-portal="goToPortal"
                :billingInfo="billingInfo"
            ></CurrentPlan>

            <AvailablePlans
                v-else
                @upgradePlan="upgradePlan"
                :billingInfo="billingInfo"
            ></AvailablePlans>
        </template>

        <div class="spacer"></div>

        <h2 class="header-subtitle">Here's what people have to say about us</h2>
        <Community></Community>

        <div class="spacer"></div>

        <h2 class="header-subtitle">Frequently asked questions</h2>
        <Accordion
            v-for="{ question, answer } in faQuestions"
            :key="question"
        >
            <template #title>
                {{ question }}
            </template>
            <template #content>
                {{ answer }}
            </template>
        </Accordion>
    </div>
</template>

<style scoped>
h2 {
    margin-bottom: 32px;
}

/* Loading */

.loading {
    padding: 64px;
    background-color: white;
    box-shadow: 0px 30px 30px 0px #11094E0D;
    border-radius: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #1f1f1f;
}

.loading-message {
    margin-top: 16px;
    font-size: 16px;
    font-family: Quicksand;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
}

.do-not-close {
    font-size: 14px;
    font-weight: 500;
}

/* Spacer */

.spacer {
    height: 4px;
    width: 100%;
    background-color: #F3F0FF;
    margin: 48px 0;
}

/* Summer Sale */

.banner-sale {
    background-color: white;
    border-radius: 24px;
    width: 100%;
    padding: 32px;
    margin-bottom: 32px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
    background: url(/src/assets/img/back_to_business_background.png);
    background-position: right;
    background-size: cover;
}

.banner-sale h3 {
    font-size: 32px;
    font-weight: 700;
    color: white;
    font-family: Gilroy;
    margin-bottom: 32px;
}

.banner-sale > div {
    color: white;
    font-family: Quicksand;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.banner-sale .main-text {
    font-size: 18px;
    margin-bottom: 24px;
}

.banner-sale ul {
    margin-left: 24px;
}

.banner-sale li {
    display: flex;
    align-items: center;
    gap: 6px;
}

.banner-sale li:not(:last-child) {
    margin-bottom: 20px;
}

.banner-sale b {
    font-weight: 700;
}

.banner-sale .copy {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    background-color: white;
    border-radius: 9999px;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    color: black;
    mix-blend-mode: screen;
    cursor: pointer;
    margin-left: 3px;
    transition: all linear .05s;
}

.banner-sale .copy:active {
    background-color: rgba(255, 255, 255, 0.918);
}

.copy > img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
}

.banner-sale .subtext {
    align-self: end;
    margin-top: 24px;
    font-size: 14px;
}
</style>
