/* eslint-disable no-multi-spaces */
import { createRouter, createWebHistory } from "vue-router";

import { API } from "@/services/api";

import Authorize from "@/pages/auth/Authorize.vue";
import BugReport from "@/pages/bug-report/BugReport.vue";
import Collection from "@/pages/collections/Collection.vue";
import Collections from "@/pages/collections/Collections.vue";
import Home from "@/pages/home/Home.vue";
import InviteRedirect from "@/pages/auth/InviteRedirect.vue";
import Logout from "@/pages/auth/Logout.vue";
import PdfViewer from "@/pages/pdf-viewer/PdfViewer.vue";
import Profile from "@/pages/profile/Profile.vue";
import ResetPassword from "@/pages/auth/ResetPassword.vue";
import Result from "@/pages/collections/results/Result.vue";
import SignIn from "@/pages/auth/SignIn.vue";
import SignUp from "@/pages/auth/SignUp.vue";
import Subscription from "@/pages/subscription/Subscription.vue";
import Rewards from "@/pages/rewards/Rewards.vue";

const routes = [
    { path: "/signin",          component: SignIn, meta: { public: true } },
    { path: "/signup",          component: SignUp, meta: { public: true } },
    { path: "/logout",          component: Logout, meta: { public: true } },
    { path: "/reset-password",  component: ResetPassword, meta: { public: true } },
    { path: "/invite",          component: InviteRedirect, meta: { public: true } },
    { path: "/authorize",       component: Authorize },

    { path: "/",                component: Home },
    { path: "/pdf-viewer",      component: PdfViewer },
    { path: "/collections",     component: Collections },
    {
        path: "/collections/:collection_slug",
        component: Collection,
    },
    {
        path: "/collections/:collection_slug/results/:item_id",
        component: Result,
    },
    { path: "/rewards",         component: Rewards },
    { path: "/profile",         component: Profile },
    { path: "/subscription",    component: Subscription },
    { path: "/bug-report",      component: BugReport },

    { path: "/account",         redirect: "/profile" },
    { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return { el: to.hash, top: 150, behavior: "smooth" };
        }
        return { top: 0 };
    },
});

router.beforeEach(async (to) => {
    if (!to.meta.public && !(await API.$auth.isLoggedIn())) {
        return { path: "/signin", query: { redirect: encodeURIComponent(to.fullPath) } };
    }

    return true;
});

export default router;
