<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

import { API } from "@/services/api";
import Button from "@/components/common/Button.vue";
import Input from "@/components/common/Input.vue";
import Popup from "@/components/common/Popup.vue";
import SplitNumberInput from "@/components/common/SplitNumberInput.vue";
import useUserStore from "@/stores/user";

import emailIcon from "@/assets/img/icons/email.svg";
import lockIcon from "@/assets/img/icons/lock.svg";

const router = useRouter();
const userStore = useUserStore();

const saving = ref(false);
const userMarketingSettings = ref({ subscribed: true });

// Account info

const accountInfo = reactive({
    email: userStore.user.email,
    email_confirmed: userStore.user.email_confirmed,
});
const accountInfoError = ref(null);

async function saveAccountInfo(code = null) {
    // Validation
    if (!accountInfo.email) {
        accountInfoError.value = "Please enter an email address";
        return;
    }

    saving.value = true;

    const data = {
        ...accountInfo,
    };

    if (code) data.code = code;

    try {
        await userStore.update(data);

        accountInfo.email = userStore.user.email;
        accountInfo.email_confirmed = userStore.user.email_confirmed;
    } catch (err) {
        if (err.error === "email_confirm") {
            showConfirmEmailPopup.value = true;
        } else {
            accountInfoError.value = err.message;
        }
    }

    saving.value = false;
}

// Confirm email

const showConfirmEmailPopup = ref(false);
const emailVerificationCode = ref();

async function submitEmailConfirmationCode() {
    showConfirmEmailPopup.value = false;
    saveAccountInfo(emailVerificationCode.value);
}

function handleUnexpectedConfirmEmailPopupClose() {
    accountInfo.email = userStore.user.email;
    accountInfoError.value = "Email change cancelled.";
}

// Change password

const changePassword = reactive({
    newPassword: "",
    newPasswordConfirm: "",
    passwordError: "",
});

async function saveNewPassword() {
    // Validation
    if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[ -/:-@[-`{-~])/.test(changePassword.newPassword)) {
        // eslint-disable-next-line max-len
        changePassword.passwordError = "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character and be longer than or equal to 8 characters";
        return;
    }

    if (changePassword.newPassword !== changePassword.newPasswordConfirm) {
        changePassword.passwordError = "Passwords do not match";
        return;
    }

    saving.value = true;

    const data = {
        password: changePassword.newPassword,
    };

    try {
        await userStore.update(data);
        await userStore.removeTokens();
        // eslint-disable-next-line no-alert, max-len
        window.alert("Your password has been changed and all your sessions have been logged out. Please sign in again to continue.");
        router.push("/signin");
    } catch (err) {
        changePassword.passwordError = err.message;
    }

    saving.value = false;
}

// Delete account

async function deleteAccount() {
    // eslint-disable-next-line no-alert, max-len
    if (window.confirm("Your account will be permanently deleted. All active subscriptions will be canceled and will not be refunded. Are you sure you want to delete your account?")) {
        await userStore.deleteAccount();
        // eslint-disable-next-line no-alert, max-len
        window.alert("Your account was deleted successfully. You will be redirected to the sign in page.");
        router.push("/signin");
    }
}

// OAuth

const googleOAuthButton = ref(null);
const oauthError = ref(null);

function isOAuthLinked(type) {
    return userStore.user.auths?.find((e) => e.type === type);
}

function handleOAuthError(err) {
    switch (err.error) {
    case "oauth_already_linked":
        oauthError.value = "An OAuth account is already linked to your Wiseone account";
        break;
    case "oauth_apple_no_email":
        oauthError.value = "The selected Apple account doesn't have an email address";
        break;
    case "oauth_unlink_last":
        oauthError.value = "You cannot unlink your last authentication method";
        break;
    default:
        oauthError.value = err.message;
        break;
    }
}

async function handleAppleOAuthResponse() {
    let response;
    try {
        response = await window.AppleID.auth.signIn();
    } catch (e) {
        return;
    }
    if (response && response.authorization && response.authorization.id_token) {
        try {
            await userStore.linkWithApple({ id_token: response.authorization.id_token });
        } catch (err) {
            handleOAuthError(err);
        }
    }
}

async function handleGoogleOAuthResponse(response) {
    if (response && response.credential) {
        try {
            await userStore.linkWithGoogle({ credential: response.credential });
        } catch (err) {
            handleOAuthError(err);
        }
    }
}

async function unlinkFromApple() {
    try {
        await userStore.unlinkFromApple();
    } catch (err) {
        handleOAuthError(err);
    }
}

async function unlinkFromGoogle() {
    try {
        await userStore.unlinkFromGoogle();
    } catch (err) {
        handleOAuthError(err);
    }
}

// Newsletter

async function updateUserMarketingSettings(data) {
    await API.user.updateUserMarketingSettings({ subscribed: data });
    userMarketingSettings.value = await API.user.getUserMarketingSettings();
}

onMounted(async () => {
    if (window.AppleID) {
        window.AppleID.auth.init({
            clientId: import.meta.env.VITE_APPLE_CLIENT_ID,
            redirectURI: window.location.href.split(/[?#]/)[0],
            scope: "email",
            usePopup: true,
        });
    }

    if (window.google) {
        window.google.accounts.id.initialize({
            client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
            callback: handleGoogleOAuthResponse,
        });

        window.google.accounts.id.renderButton(
            googleOAuthButton.value,
            {
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en_US",
                width: 375,
            },
        );
    }

    userMarketingSettings.value = await API.user.getUserMarketingSettings();
});
</script>

<template>
    <div>
        <div class="header">
            <h1 class="header-title">Profile</h1>
            <h3 class="header-subtitle">Hello!</h3>
            <span class="header-description">Manage your account information</span>
        </div>

        <div class="setting">
            <h3 class="setting-name">Account info</h3>
            <form @submit.prevent="saveAccountInfo()">
                <Input
                    class="form-input"
                    labelKey="email"
                    label="Email"
                    autocomplete="email"
                    :icon="emailIcon"
                    v-model="accountInfo.email"
                />

                <div class="error" v-if="accountInfoError">
                    <span class="material-icons">error</span>
                    <span>{{ accountInfoError }}</span>
                </div>
            </form>
            <div v-if="!accountInfo.email_confirmed" class="confirm-email-text">
                Your email isn't confirmed yet.
                <div
                    class="confirm-button"
                    @click="showConfirmEmailPopup = true"
                >
                    Confirm now!
                </div>
            </div>
            <Popup v-model:show="showConfirmEmailPopup" @closed="handleUnexpectedConfirmEmailPopupClose()">
                <template #header>
                    <img src="@/assets/img/icons/checkmark.svg" class="invite-icon">
                    <h1>Confirm your email</h1>
                </template>
                <div class="popup-content">
                    <div class="split-numbers-form">
                        <div class="label">
                            Confirm your email with the code that was sent to your email adress
                        </div>
                        <SplitNumberInput
                            v-model="emailVerificationCode"
                            class="split-numbers-container"
                        ></SplitNumberInput>
                    </div>
                    <Button
                        class="submit-button"
                        text="Confirm"
                        :disabled="saving"
                        @click="submitEmailConfirmationCode"
                    ></Button>
                </div>
            </Popup>
            <Button
                class="submit-button"
                text="Save"
                :disabled="saving"
                @click="saveAccountInfo()"
            ></Button>
        </div>

        <div class="setting">
            <h3 class="setting-name">Change password</h3>
            <p class="setting-description">
                Manage your password
            </p>

            <form @submit.prevent="saveNewPassword">
                <input v-show="false" autocomplete="email">

                <Input
                    class="form-input"
                    labelKey="new_password"
                    label="New password"
                    autocomplete="new-password"
                    :icon="lockIcon"
                    v-model="changePassword.newPassword"
                    :password="true"
                    :disabled="saving"
                />
                <Input
                    class="form-input"
                    labelKey="confirm_new_password"
                    label="Confirm new password"
                    autocomplete="new-password"
                    :icon="lockIcon"
                    v-model="changePassword.newPasswordConfirm"
                    :password="true"
                    :disabled="saving"
                />

                <div class="error" v-if="changePassword.passwordError">
                    <span class="material-icons">error</span>
                    <span>{{ changePassword.passwordError }}</span>
                </div>
                <input type="submit" hidden />
            </form>
            <Button
                text="Save"
                :disabled="saving"
                @click="saveNewPassword"
                class="submit-button"
            ></Button>
        </div>

        <div class="setting">
            <h3 class="setting-name">Connections</h3>
            <p class="setting-description">
                You can link other accounts to your Wiseone account so you can log in more easily.
            </p>

            <div class="error oauth-error" v-if="oauthError">
                <span class="material-icons">error</span>
                <span>{{ oauthError }}</span>
            </div>

            <form class="connection" @submit.prevent>
                <button
                    class="oauth-button custom-oauth-button"
                    v-show="isOAuthLinked('GOOGLE')"
                    @click="unlinkFromGoogle">
                    <img src="@/assets/img/google_logo.svg" />
                    <span style="color: #d63200">
                        Unlink Google account
                    </span>
                </button>
                <div ref="googleOAuthButton"
                    class="oauth-button"
                    v-show="!isOAuthLinked('GOOGLE')">
                </div>

                <button
                    class="oauth-button custom-oauth-button"
                    @click="unlinkFromApple"
                    v-if="isOAuthLinked('APPLE')">
                    <img src="@/assets/img/apple_logo_black.svg" />
                    <span style="color: #d63200">
                        Unlink Apple ID
                    </span>
                </button>
                <button
                    class="oauth-button custom-oauth-button"
                    @click="handleAppleOAuthResponse"
                    v-else
                >
                    <img src="@/assets/img/apple_logo_black.svg" />
                    <span>
                        Continue with Apple
                    </span>
                </button>
            </form>
        </div>

        <div class="setting">
            <h3 class="setting-name">Newsletter</h3>
            <p class="setting-description">
                Receive our newsletter and be the first to know about Wiseone's enhancements, updates, expert tips,
                and exclusive content delivered straight to your inbox.
            </p>

            <Button
                class="submit-button grey"
                text="Unsubscribe"
                :disabled="saving"
                @click="updateUserMarketingSettings(false)"
                v-if="userMarketingSettings.subscribed"
            ></Button>
            <Button
                class="submit-button"
                text="Subscribe"
                :disabled="saving"
                @click="updateUserMarketingSettings(true)"
                v-else
            ></Button>
        </div>

        <div class="setting">
            <h3 class="setting-name">Danger Zone</h3>
            <p class="setting-description">
                Delete your Wiseone account permanently.
            </p>

            <Button
                class="submit-button danger-button"
                text="Delete account"
                :disabled="saving"
                @click="deleteAccount"
            ></Button>
        </div>
    </div>
</template>

<style scoped>
/* Settings */

.setting {
    padding: 40px 32px;
    background-color: white;
    width: 100%;
    border-radius: 24px;
    box-shadow: 0px 30px 30px 0px #11094E0D;
}

.setting > *:not(:last-child) {
    margin-bottom: 16px;
}

.setting:not(:last-child) {
    margin-bottom: 32px;
}

.setting-name {
    font-size: 20px;
    font-family: Quicksand;
    font-weight: 700;
    color: #1f1f1f;
}

.setting-description {
    font-size: 16px;
    font-family: Quicksand;
    font-weight: 500;
    color: #9D96BA;
    line-height: 24px;
}

.logo {
    height: 16px;
}

.logo-label {
    line-height: 16px;
}

.logo-label > .logo {
    margin-right: 5px;
}

/* Input styling */

.form-input {
    width: 440px;
}

@media (max-width: 760px) {
    .form-input {
        width: 100%;
    }
}

.form-input:not(:last-of-type) {
    margin-bottom: 16px;
}

button {
    font-family: Quicksand;
}

.submit-button {
    color: white !important;
    background-color: #15AED3;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0px 4px 20px rgba(47, 34, 96, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    transition: .1s linear;
}

.submit-button:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

.danger-button {
    background-color: #EB5757;
}

.danger-button:hover {
    background-color: rgb(216, 8, 8);
}

/* OAuth styling */

.oauth-button {
    width: 375px;
    height: 44px;
    cursor: pointer;
    background-color: #FFFFFF;
    user-select: none;
}

@media (max-width: 760px) {
    .oauth-button {
        width: 100%;
    }
}

.custom-oauth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    color: #3C4043;
    font-weight: 600;
    border: 1px solid #DAD5EF;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
    transition: linear 0.1s;
}

.custom-oauth-button:not(:last-of-type) {
    margin-bottom: 16px;
}

.custom-oauth-button > span {
    flex: 1;
}

.custom-oauth-button > img {
    height: 21.24px;
    width: 18px;
}

.custom-oauth-button:hover {
    border: 1px solid #15AED3;
}

.oauth-error {
    margin: 10px 0;
}

/* Email Confirm popup */

.confirm-email-text {
    display: flex;
    gap: 6px;
    font-weight: 400;
    font-family: 'Quicksand';
}

.confirm-button {
    font-weight: 500;
    color: #15AED3;
    cursor: pointer;
    text-decoration: underline;
}

.confirm-button:hover {
    color: #2d00d4;
}

.label {
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 700;
    margin-bottom: 12px;
}

.split-numbers-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.split-numbers-container {
    align-self: center;
}

/* Error styling */

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.error {
    color: red;
    font-size: 15px;
    margin-top: 0px;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

.error > * {
    vertical-align: middle;
}

.error > *:not(:last-child) {
    margin-right: 4px;
}
</style>
