<script setup>
import { reactive, ref, watchEffect, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import chromeIcon from "@/assets/img/logo/available-on-chrome.png";
import edgeIcon from "@/assets/img/logo/available-on-edge.png";
import bookOpenIcon from "@/assets/img/icons/book_open.svg";
import closeIcon from "@/assets/img/icons/close.svg";
import flashIcon from "@/assets/img/icons/flash.svg";
import fileIcon from "@/assets/img/icons/file.svg";
import homeIcon from "@/assets/img/icons/home.svg";
import menuIcon from "@/assets/img/icons/menu.svg";
import personIcon from "@/assets/img/icons/person.svg";
import giftIcon from "@/assets/img/icons/gift.svg";

import Tooltip from "@/components/common/Tooltip.vue";

import useCollectionsStore from "@/stores/collections";
import useUserStore from "@/stores/user";

const router = useRouter();
const route = useRoute();

const userStore = useUserStore();
const collectionsStore = useCollectionsStore();

async function logout() {
    await userStore.logout();
    router.push("/signin");
}

const displayLinks = ref(false);

const links = reactive({
    "": {
        displayName: "Home",
        icon: homeIcon,
    },
    "pdf-viewer": {
        displayName: "PDF Viewer",
        icon: fileIcon,
    },
    collections: {
        displayName: "Collections",
        icon: bookOpenIcon,
    },
    subscription: {
        displayName: "Subscription",
        icon: flashIcon,
    },
    rewards: {
        displayName: "Rewards",
        icon: giftIcon,
    },
    profile: {
        displayName: "Profile",
        icon: personIcon,
    },
});

watchEffect(async () => {
    links.collections.subLinks = Object.fromEntries([
        ...collectionsStore.recentCollections.slice(0, 3).map((coll) => [coll.slug, coll.name]),
    ]);
});

function isActiveLink(path) {
    return route.path === `/${path}` || route.path.startsWith(`/${path}/`);
}

// Browser

const browsers = {
    chrome: {
        name: "Chrome",
        icon: chromeIcon,
        storeUrl: "https://chromewebstore.google.com/detail/wiseone-your-ai-powered-r/paodpkkacimmkacaecjmhdncjgjepcai",
    },
    edge: {
        name: "Edge",
        icon: edgeIcon,
        storeUrl: "https://microsoftedge.microsoft.com/addons/detail/wiseone-your-aipowered/pcgccjfobmmgjkkpcabmiiefmnjegpfg",
    },
};

const browser = ref("chrome");
onMounted(() => {
    // @ts-expect-error userAgentData is experimental and missing from current DOM typing
    if (window.navigator.userAgentData?.brands?.find((e) => e.brand === "Microsoft Edge")) {
        browser.value = "edge";
    }
});
</script>

<template>
    <div class="sidebar-container">
        <div class="main-sidebar-content">
            <div class="brand">
                <RouterLink
                    to="/"
                    @click="displayLinks = false"
                >
                    <img class="logo full" src="@/assets/img/logo_full.svg" alt="wiseone main logo">
                    <img class="logo small" src="@/assets/img/logo_small.svg" alt="wiseone main logo">
                </RouterLink>
                <img
                    @click="displayLinks = !displayLinks"
                    :src="displayLinks ? closeIcon : menuIcon"
                    class="display-link-switch"
                >
            </div>
            <div
                class="main-links"
                :class="{ 'hide-links': !displayLinks }"
                @click="displayLinks = false"
            >
                <template
                    v-for="(link, linkPath) in links"
                    :key="linkPath"
                >
                    <RouterLink
                        :to="`/${linkPath}`"
                        class="action main-link"
                        :class="{ 'active': isActiveLink(linkPath) }"
                    >
                        <div class="action-icon">
                            <img :src="link.icon"/>
                        </div>
                        <span class="action-name">{{ link.displayName }}</span>
                    </RouterLink>

                    <div
                        v-if="link.subLinks && Object.keys(link.subLinks).length && isActiveLink(linkPath)"
                        class="sub-links"
                    >
                        <RouterLink
                            v-for="(subLinkName, subLinkPath) in link.subLinks"
                            :key="subLinkPath"
                            :to="`/${linkPath}/${subLinkPath}`"
                            class="sub-link"
                            :class="{ 'active': route.path === `/${linkPath}/${subLinkPath}` }"
                        >
                            {{ subLinkName }}
                        </RouterLink>
                    </div>
                </template>
            </div>
        </div>
        <div
            class="sidebar-footer-content"
            :class="{ 'hide-links': !displayLinks }"
            @click="displayLinks = false"
        >
            <Tooltip text="Log out">
                <a class="footer-action" @click="logout" href="#">
                    <img src="@/assets/img/icons/logout.svg"/>
                </a>
            </Tooltip>
            <Tooltip text="Website">
                <a class="footer-action" href="https://wiseone.io" target="_blank">
                    <img src="@/assets/img/icons/globe.svg"/>
                </a>
            </Tooltip>
            <Tooltip text="Terms &amp; conditions">
                <a class="footer-action" href="https://wiseone.io/terms" target="_blank">
                    <img src="@/assets/img/icons/terms.svg"/>
                </a>
            </Tooltip>
            <Tooltip text="Privacy policy">
                <a class="footer-action" href="https://wiseone.io/privacy" target="_blank">
                    <img src="@/assets/img/icons/lock.svg"/>
                </a>
            </Tooltip>
            <Tooltip text="Support">
                <a class="footer-action" href="mailto:support@wiseone.io" target="_blank">
                    <img src="@/assets/img/icons/support.svg"/>
                </a>
            </Tooltip>
        </div>
        <div class="links" :class="{ 'hide-links': !displayLinks }">
            <a v-if="browser && browsers[browser].icon" :href="browsers[browser].storeUrl" target="_blank">
                <img :src="browsers[browser].icon" />
            </a>
        </div>
    </div>
</template>

<style scoped>
.sidebar-container {
    height: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    user-select: none;
    padding: 72px 48px 48px;
    font-family: Quicksand;
}

@media (max-width: 1440px) and (min-width: 1021px) {
    .sidebar-container {
        padding: 72px 32px 32px;
        max-width: 233px;
    }
}

@media (max-width: 1020px) {
    .sidebar-container {
        position: fixed;
        height: unset;
        width: 100%;
        max-width: unset;
        background-color: white;
        padding: 32px 24px;
        box-shadow: 0px 5px 10px 0px #11094e36;
    }
}

/* Main content */

.main-sidebar-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.main-links {
    margin-top: 42px;
    margin-bottom: auto;
}

/* @media (max-width: 1440px) and (min-width: 1021px) {
    .main-links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
} */

@media (max-width: 1020px) {
    .main-sidebar-content {
        flex: unset;
    }

    .hide-links {
        display: none !important;
    }
}

a {
    color: #242424;
    text-decoration: none;
    letter-spacing: .5px;
}

.main-link {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid transparent;
    transition: .075s linear;

    font-size: 16px;
    font-weight: 500;
}

.main-link:not(:last-child) {
    margin-bottom: 16px;
}

.main-link:hover, .main-link.active {
    font-weight: 700;
}

a > * {
    vertical-align: middle;
}

a:not(.footer-action) > :not(:first-child) {
    margin-left: 16px;
}

a > img {
    max-width: 100%;
}

.main-link > .action-icon {
    font-size: 24px;
    padding: 8px;
    border-radius: 12px;
}

.main-link > .action-icon > img {
    height: 24px;
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

.action-icon {
    display: flex;
}

.main-link.active > .action-icon {
    background-color: #2D00D4;
    box-shadow: 0px 10px 10px 0px #2D00D333;
}

.main-link.active > .action-icon > img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(341deg) brightness(101%) contrast(105%);
}

.copyright {
    margin-top: 8px;
    padding: 10px 6px;
}

/* Sub links */

.sub-links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 20px;
    padding-left: 36px;
    border-left: 1px solid #e9e9e9;
    margin-bottom: 18px;
}

@media (max-width: 1440px) and (min-width: 1021px) {
    .sub-links {
        padding-left: 24px;
    }
}

.sub-link {
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: .075s linear;
}

.sub-link:hover, .sub-link.active {
    font-weight: 700;
}

/* Footer content */

.sidebar-footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-height: 1020px) {
    .sidebar-footer-content {
        max-width: 200px;
        gap: 20px;
        margin-top: 24px;
    }
}

.footer-action > img {
    height: 24px;
    width: 24px;
    margin: 8px 24px 8px 8px;
    transition: .075s linear;
    filter: invert(68%) sepia(28%) saturate(296%) hue-rotate(211deg) brightness(87%) contrast(85%);
}

.footer-action:hover > img {
    filter: invert(13%) sepia(98%) saturate(5221%) hue-rotate(257deg) brightness(73%) contrast(142%);
}

.footer-action img {
    margin: 0;
}

/* Socials */

.links {
    display: flex;
    flex-direction: column;
    padding-top: 18px;
}

.links > *:not(:last-child) {
    padding-bottom: 15px;
}

@media (max-height: 1020px) {
    .links {
        max-width: 200px;
    }
}

/* Brand styling */

.brand {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.brand > img {
    height: 24px;
    width: 24px;
}

.logo {
    height: 41px;
}

.logo.small {
    display: none;
    margin-left: 0 !important;
}

.display-link-switch {
    display: none;
}

@media (max-width: 1440px) and (min-width: 1021px) {
    .logo.full {
        display: none;
    }

    .logo.small {
        display: unset;
    }
}

@media (max-width: 1020px) {
    .brand {
        justify-content: space-between;
    }

    .logo {
        height: 28px;
    }

    .display-link-switch {
        display: block;
        cursor: pointer;
    }
}
</style>
