<script setup>
import { ref, onMounted, nextTick } from "vue";

const numberInput = defineModel();
const props = defineProps({
    columns: {
        type: Number,
        default: 6,
    },
    maxLength: {
        type: Number,
        default: 1,
    },
});

const values = ref([]);
const numberInputs = ref([]);

onMounted(() => {
    values.value = Array(props.columns).fill("");
});

// Listeners

function handleOnChange() {
    numberInput.value = values.value.join("");
}

function handleOnInput(e, i) {
    if (e.inputType === "insertText") navigate(numberInputs.value[i + 1]);
}

function handleOnKeydown(e, i) {
    switch (e?.code || e?.key || e?.which || e?.keyCode) {
    case "ArrowDown":
    case 40:
        navigate(numberInputs.value[props.columns - 1]);
        break;
    case "ArrowLeft":
    case 37:
        if (i === 0 || !e.target.value) {
            navigate(numberInputs.value[i - 1]);
        }
        break;
    case "ArrowRight":
    case 39:
        if (i === 1 || !e.target.value) {
            navigate(numberInputs.value[i + 1]);
        }
        break;
    case "ArrowUp":
    case 38:
        navigate(numberInputs.value[0]);
        break;
    case "Backspace":
    case 8:
        if (i !== 0) {
            values.value[i] = "";
        }
        nextTick(() => navigate(numberInputs.value[i - 1]));
        break;
    default:
        break;
    }
}

function handleOnPaste(e) {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData("text/plain");
    Object.assign(values.value, pastedValue.split("").slice(0, props.columns));
    numberInput.value = pastedValue;
}

// Utility

function navigate(input) {
    if (input) {
        input.focus();
        input.select();
    }
}

</script>

<template>
    <div class="split-numbers-container">
        <input
            v-for="val, index in values"
            ref="numberInputs"
            :key="index"
            v-model="values[index]"
            :maxlength="maxLength"
            @change="handleOnChange"
            @input="(e) => handleOnInput(e, index)"
            @keydown="(e) => handleOnKeydown(e, index)"
            @paste="handleOnPaste"
            data-1p-ignore
            data-lpignore="true"
            data-form-type="other"
            data-bwignore
        >
    </div>
</template>

<style scoped>
.split-numbers-container {
    display: flex;
    gap: 8px;
}

input {
    width: 28px;
    transition: linear 0.1s;
    border: 1px solid #DAD5EF;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-family: Quicksand;
    padding: 5px 0;
    caret-color: transparent;
    user-select: none;
}

input:hover {
    border: 1px solid #15AED3;
}

input.focus {
    outline: none;
    border: 1px solid #2D00D4;
}

:is(input, textarea):focus-visible {
    outline: none;
    border: 1px solid #2D00D4;
}
</style>
