<script setup>
import {
    onMounted,
    reactive,
    ref,
} from "vue";

import { API } from "@/services/api";
import Button from "@/components/common/Button.vue";

const report = reactive({
    comments: "",
});

onMounted(() => {
    const url = new URL(window.location.href);
    report.article_url = url.searchParams.get("article_url") ?? undefined;
    report.result_type = url.searchParams.get("result_type") ?? undefined;
    const resultData = url.searchParams.get("result_data");
    if (resultData !== null) {
        try {
            report.result_data = JSON.parse(resultData);
        } catch (err) {
            console.error(err);
        }
    }
});

const saving = ref(false);
const saved = ref(false);
const error = ref();

async function sendReport() {
    saving.value = true;
    const res = API.etc.sendBugReport(report);
    if (res?.error) {
        error.value = res.message;
    } else {
        saved.value = true;
    }
    saving.value = false;
}
</script>

<template>
    <div>
        <div class="bug-report-container">
            <div class="header">
                <h1 class="header-title">Bug Report</h1>
                <h3 class="header-subtitle">Help us improve</h3>
            </div>
            <template v-if="!saved">
                <form class="wiseone-form">
                    <textarea
                        id="user-comments"
                        v-model="report.comments"
                        required
                        :disabled="saving"
                        rows="4"
                        placeholder="Tell us what went wrong"
                    ></textarea>
                    <span class="under-label">Please don't include any sensitive information</span>

                    <div v-if="error" class="error">
                        <i class="material-icons">error</i>
                        <span>{{error}}</span>
                    </div>
                </form>
                <Button
                    class="submit-button"
                    text="Send report"
                    @click="sendReport"
                ></Button>
            </template>
            <p class="success" v-else>
                <i class="material-icons">check_circle</i>
                <span>Your report has been submitted!</span>
            </p>
        </div>
    </div>
</template>

<style scoped>
.bug-report-container {
    width: 100%;
    max-width: 800px;
}

form {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.label {
    font-size: 1em;
    font-weight: 600;
}

.under-label {
    font-size: 15px;
    font-family: Quicksand;
    font-weight: 500;
    font-style: italic;
}

.success > * {
    vertical-align: middle;
}

.success .material-icons {
    color: #63d79f;
    margin-right: 4px;
}

/* Button Styling */

.submit-button {
    color: white !important;
    background-color: #15AED3;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    padding: 14px 20px;
    box-shadow: 0px 4px 20px rgba(47, 34, 96, 0.15), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    transition: .1s linear;
    margin-left: auto;
}

.submit-button:hover {
    box-shadow: 0px 5px 20px 0px #11094e46;
}

/* Input Styling */

textarea {
    font-family: Quicksand;
    font-weight: 500;
    margin: 8px 0;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #DAD5EF;
    background-color: #fafafa;
    font-size: inherit;
    height: 250px;
    resize: none;
    outline: none;
    transition: all linear .1s;
}

textarea:hover {
    border: 1px solid #15AED3;
}

textarea:focus-visible {
    border: 1px solid #2d00d3;
}

/* Error styling */

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.error {
    color: red;
    font-size: .95em;
    margin-top: -4px;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

.error > * {
    vertical-align: middle;
}

.error > *:not(:last-child) {
    margin-right: 4px;
}
</style>
