<script setup>
import { onMounted, ref } from "vue";

import closeIcon from "@/assets/img/icons/close.svg";
import eyeOffIcon from "@/assets/img/icons/eye_off.svg";
import eyeOnIcon from "@/assets/img/icons/eye.svg";

const props = defineProps({
    labelKey: String,
    label: String,
    autocomplete: String,
    placeholder: String,
    icon: String,
    clearable: Boolean,
    password: Boolean,
    disabled: Boolean,
    required: Boolean,
    multiline: Boolean,
});
const value = defineModel();

const input = ref();
const isTextHidden = ref(false);
const isFocused = ref(false);

function adjustHeight() {
    if (!props.multiline) return;
    setTimeout(() => {
        input.value.style.height = 0;
        input.value.style.height = `${input.value.scrollHeight}px`;
    }, 0);
}

onMounted(() => {
    if (props.password) isTextHidden.value = true;
    adjustHeight();
});

</script>

<template>
    <div class="input-container" @click="input.focus()">
        <label v-if="label" :for="labelKey">{{ label }}</label>
        <div class="input" :class="{ focus: isFocused, disabled }">
            <img
                v-if="icon"
                :src="icon"
            >
            <component
                :is="multiline ? 'textarea' : 'input'"
                :id="labelKey"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :value="value"
                :type="isTextHidden ? 'password' : 'text'"
                :disabled="disabled"
                :required="required"
                ref="input"
                @change="(e) => value = e.target.value"
                @keydown="adjustHeight"
                @focusin="isFocused = true"
                @focusout="isFocused = false"
            />
            <img
                class="clear-button"
                v-if="clearable"
                @click="value = ''"
                :src="closeIcon"
            >
            <img
                class="password-eye"
                v-if="password"
                @click="isTextHidden = !isTextHidden"
                :src="isTextHidden ? eyeOnIcon : eyeOffIcon"
            >
        </div>
    </div>
</template>

<style scoped>

.input-container {
    display: flex;
    flex-direction: column;
    cursor: text;
}

label {
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 700;
    margin-bottom: 4px;
}

label > * {
    vertical-align: middle;
}

img {
    user-select: none;
    filter: invert(72%) sepia(21%) saturate(373%) hue-rotate(211deg) brightness(82%) contrast(88%);
}

.input {
    padding: 12px 8px;
    font-size: 16px;
    max-width: 100%;
    display: flex;
    align-items: center;
    transition: linear 0.1s;
    border: 1px solid #DAD5EF;
    border-radius: 8px;
}

.input.disabled {
    cursor: default;
}

.input:hover:not(.disabled) {
    border: 1px solid #15AED3;
}

.input.focus {
    outline: none;
    border: 1px solid #2D00D4;
}

.input > img {
    height: 16px;
    width: 16px;
    cursor: pointer;
}

img:first-child {
    margin-right: 8px;
}

img:last-child {
    margin-left: 8px;
}

:is(input, textarea) {
    background-color: transparent;
    border: 0;
    font-size: 14px;
    font-family: Quicksand;
    font-weight: 500;
    flex: 1;
}

:is(input, textarea):focus-visible {
    border: 0;
    outline: none;
}

textarea {
    resize: none;
    height: 22px;
    overflow-y: hidden;
}
</style>
